import * as React from 'react';

import { StileGtagEventName } from '../../../../types/services';

import { Button } from '../Button';
import { Link } from '../Link';
import { Logo } from '../Logo';
import { TrialButton } from '../TrialButton/TrialButton';
import './Header.css';

export type HeaderLinkSection = {
  title: string;
  links: { text: string; url: string }[];
};

type HeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  logoColor: Colors;
  headerLinks: HeaderLinkSection[];
  enquireNowGtagEvent?: StileGtagEventName;
};

/**
 * Displays stile Header in different colours
 */
export const Header = ({
  logoColor,
  headerLinks,
  enquireNowGtagEvent = 'enquire_now_au',
  ...props
}: React.PropsWithChildren<HeaderProps>) => {
  const [mobileMenuShown, setMobileMenuShown] = React.useState(false);

  return (
    <header {...props}>
      <div className="content">
        <Link url="/">
          <Logo color={logoColor} />
        </Link>
        <nav className="desktopNav">
          {headerLinks.map((section) => (
            <div key={section.title}>
              <div>{section.title}</div>
              <ul>
                {section.links.map((link) => (
                  <li key={link.url}>
                    <Link url={link.url}>{link.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </nav>
        <div className="grow"></div>
        <div className="buttons">
          <Button label="Log in" style="secondary" href="https://stileapp.com/login" />
          <TrialButton gtagEvent={enquireNowGtagEvent} label="Enquire now" />
          <button
            className="mobile-menu-toggle"
            onClick={() => setMobileMenuShown(!mobileMenuShown)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <rect width="34" height="34" rx="6" fill="#00E65A" />
              <line
                x1="10"
                y1="22"
                x2="24"
                y2="22"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <line
                x1="10"
                y1="16"
                x2="24"
                y2="16"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <line
                x1="10"
                y1="10"
                x2="24"
                y2="10"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className={`content mobile-menu ${mobileMenuShown ? '--open' : ''}`}>
        <div className="buttons">
          <Button label="Log in" style={'secondary'} href="https://stileapp.com/login" />
          <TrialButton gtagEvent={enquireNowGtagEvent} label="Enquire now" />
          <Button
            label={
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 7.27757L1.52852 11.7491C1.36122 11.9164 1.14829 12 0.889734 12C0.631179 12 0.418251 11.9164 0.250951 11.7491C0.0836502 11.5817 0 11.3688 0 11.1103C0 10.8517 0.0836502 10.6388 0.250951 10.4715L4.72243 6L0.250951 1.52852C0.0836502 1.36122 0 1.14829 0 0.889734C0 0.631179 0.0836502 0.418251 0.250951 0.250951C0.418251 0.0836502 0.631179 0 0.889734 0C1.14829 0 1.36122 0.0836502 1.52852 0.250951L6 4.72243L10.4715 0.250951C10.6388 0.0836502 10.8517 0 11.1103 0C11.3688 0 11.5817 0.0836502 11.7491 0.250951C11.9164 0.418251 12 0.631179 12 0.889734C12 1.14829 11.9164 1.36122 11.7491 1.52852L7.27757 6L11.7491 10.4715C11.9164 10.6388 12 10.8517 12 11.1103C12 11.3688 11.9164 11.5817 11.7491 11.7491C11.5817 11.9164 11.3688 12 11.1103 12C10.8517 12 10.6388 11.9164 10.4715 11.7491L6 7.27757Z"
                  fill="white"
                />
              </svg>
            }
            style={'grey'}
            href="#"
            onClick={() => setMobileMenuShown(false)}
          />
        </div>
        <nav className="mobileNav">
          {headerLinks.map((section) => (
            <ul key={section.title}>
              <li>
                <span>{section.title}</span>
                <ul>
                  {section.links.map((link) => (
                    <li key={link.url}>
                      <Link url={link.url}>{link.text}</Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          ))}
        </nav>
      </div>
    </header>
  );
};
