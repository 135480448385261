import * as React from 'react';

import './Logo.css';

type LogoProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: never;
  color: Colors;
};

/**
 * Displays stile logo in different colours
 */
export const Logo = ({ color, ...props }: React.PropsWithChildren<LogoProps>) => {
  return (
    <div className={``} style={{ color: `var(${color})` }} {...props}>
      <svg width="82" height="31" viewBox="0 0 50 19" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_16_601)">
          <path
            d="M9.89734 8.14318L6.33203 7.18768C4.69198 6.75984 4.24988 6.06104 4.24988 5.21962C4.24988 3.83628 5.6047 3.06617 7.35884 3.06617C8.92758 3.06617 10.1683 3.75071 11.3948 4.66343C11.9367 5.07701 12.3788 5.03423 12.7781 4.63491L13.8762 3.52253C14.1615 3.23731 14.4039 2.82373 13.7764 2.22476C12.5499 1.08386 10.3537 0 7.47293 0C3.42273 0 0.499168 2.25328 0.484907 5.4478C0.484907 7.97205 2.06791 9.66914 4.9059 10.4107L8.21451 11.2807C10.0114 11.7798 10.9527 12.4073 10.9384 13.6623C10.9242 14.9173 9.8403 15.8728 7.64406 15.8728C5.44783 15.8728 4.05022 14.8745 3.00915 13.8477C2.6241 13.4626 2.28183 13.2772 1.78268 13.6623L0.313772 14.7747C-0.185372 15.1597 -0.0855436 15.7872 0.342294 16.2151C1.54024 17.5414 3.82204 18.9675 7.43014 18.9675C12.3788 18.9817 14.7319 16.101 14.7462 13.3343C14.7462 10.4392 12.8352 8.89903 9.88308 8.12892L9.89734 8.14318Z"
            fill="currentColor"
          />
          <path
            d="M43.4827 5.44788C39.5608 5.44788 36.7513 8.47126 36.7513 12.2648C36.7513 16.0583 39.5323 18.9676 43.5397 18.9676C47.5471 18.9676 48.0177 17.8552 49.0873 16.5004C49.2727 16.2864 49.2727 15.9442 48.9875 15.7303L47.3189 14.5608C47.2476 14.518 47.1621 14.4895 47.0622 14.4895C46.9196 14.4895 46.8055 14.5466 46.7057 14.6321C46.6772 14.6607 46.6629 14.6749 46.6344 14.7034C45.8643 15.5734 44.9944 16.0583 43.6395 16.0583C42.2847 16.0583 40.4878 14.8603 40.3024 13.0634H49.4296C49.9288 13.0634 50.0428 12.7924 50.0428 12.4787V11.8369C50.0428 8.44274 47.4758 5.44788 43.5254 5.44788H43.4827ZM40.5306 10.1684C41.044 9.04172 42.1278 8.34291 43.4541 8.34291C44.7804 8.34291 45.8786 8.95615 46.3777 10.1684H40.5306Z"
            fill="currentColor"
          />
          <path
            d="M23.9304 17.2847L23.0462 15.8728C22.8893 15.6304 22.5756 15.5448 22.3189 15.6732C21.9766 15.8586 21.506 16.0582 21.0353 16.0582C20.0371 16.0582 19.652 15.2596 19.652 14.461C19.652 13.6623 19.652 8.34287 19.652 8.34287H23.4027C23.7022 8.34287 23.9589 8.07191 23.9589 7.78668V6.01829C23.9589 5.70454 23.6879 5.4621 23.4027 5.4621H19.652V2.23905C19.652 1.93956 19.381 1.68286 19.0958 1.68286H16.7855C16.4717 1.68286 16.2293 1.95383 16.2293 2.23905V5.44784H14.4609C14.1614 5.44784 13.9332 5.69028 13.9332 6.00402V7.77242C13.9332 8.07191 14.1757 8.32861 14.4609 8.32861H16.2293C16.2293 11.2094 16.2293 14.1044 16.2293 14.8888C16.2293 17.1421 17.6839 18.8106 20.2224 18.939C21.9195 19.0246 23.1317 18.4684 23.7878 18.012C24.0302 17.8409 24.1015 17.5129 23.9446 17.2562L23.9304 17.2847Z"
            fill="currentColor"
          />
          <path
            d="M33.571 14.4609V0.556189C33.571 0.256703 33.3001 0 33.0148 0H30.7045C30.3908 0 30.1483 0.270964 30.1483 0.556189V14.9173C30.1483 17.1706 31.603 18.8391 34.1415 18.9675C35.753 19.0531 36.8796 18.5539 37.4929 18.1689C37.7638 18.012 37.8351 17.6554 37.6783 17.3845L36.7941 15.9726C36.6372 15.7302 36.3377 15.6446 36.081 15.7587C35.8243 15.8728 35.3822 16.0439 34.9829 16.0439C33.9846 16.0439 33.5995 15.2453 33.5995 14.4467"
            fill="currentColor"
          />
          <path
            d="M28.6794 9.22711V6.00407C28.6794 5.70458 28.4085 5.44788 28.1232 5.44788H25.8129C25.4992 5.44788 25.2567 5.71884 25.2567 6.00407V18.4114C25.2567 18.7109 25.5277 18.9676 25.8129 18.9676H28.1232C28.437 18.9676 28.6794 18.6966 28.6794 18.4114V9.22711Z"
            fill="currentColor"
          />
          <path
            d="M26.9681 0C25.8985 0 25.0286 0.884198 25.0286 1.93953C25.0286 2.99487 25.8842 3.87906 26.9681 3.87906C28.052 3.87906 28.8791 2.99487 28.8791 1.93953C28.8791 0.884198 28.0234 0 26.9681 0Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_16_601">
            <rect width="50" height="18.9817" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
