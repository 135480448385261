import * as React from 'react';

import { Grid } from '../Grid';
import './Section.css';

type SectionProps = React.HTMLAttributes<HTMLDivElement> & {
  backgroundColor?: Colors;
  subHeadingColor?: Colors;
  subHeadingContent?: React.ReactElement | string;
  headingColor?: Colors;
  headingContent?: React.ReactElement | string;
  textColor?: Colors;
  children: React.ReactNode;
};

/**
 * Displays content in a simple Section.
 */
export const Section = ({
  backgroundColor,
  subHeadingColor,
  subHeadingContent,
  headingColor,
  headingContent,
  textColor,
  children,
  className,
  ...props
}: React.PropsWithChildren<SectionProps>) => {
  return (
    <div
      className={`section ${className}`}
      style={{
        backgroundColor: `${backgroundColor ? `var(${backgroundColor})` : `var(--white)`}`,
      }}
      {...props}
    >
      <div className="content" style={{ color: `${textColor ? `var(${textColor})` : `inherit`}` }}>
        <div className="subheading">
          <h6
            className="h9"
            style={{
              color: `${subHeadingColor ? `var(${subHeadingColor})` : `inherit`}`,
            }}
          >
            {subHeadingContent}
          </h6>
        </div>
        <Grid>
          {headingContent && (
            <h2 style={{ color: `${headingColor ? `var(${headingColor})` : `inherit`}` }}>
              {headingContent}
            </h2>
          )}
          {children}
        </Grid>
      </div>
    </div>
  );
};
