import * as React from 'react';

// import { TRIAL_PAGE_URL } from '../../../../constants';
import { useModal } from '../../../2020/common/Modal';
import { TrialModal } from '../../../2020/common/TrialModal';

import { Button } from '../Button';
import './TrialButton.css';

type TrialButtonProps = React.ComponentProps<typeof Button> & {
  // usesModal?: boolean;
};

/**
 * Displays stile TrialButton in different colours
 */
export const TrialButton = ({ gtagEvent, ...props }: React.PropsWithChildren<TrialButtonProps>) => {
  const { openModal, closeModal, isModalOpen } = useModal();

  return (
    <>
      <Button {...props} gtagEvent={gtagEvent} onClick={openModal} />
      <TrialModal isOpen={isModalOpen} closeModal={closeModal} gtagEvent={gtagEvent} />
    </>
  );
};
