import * as React from 'react';

import {
  CONTACT,
  FOOTER_LINK_SECTIONS,
  HEADER_LINK_SECTIONS,
  OTHER_SITE_LINKS,
  SITE_LOCALE,
} from 'app-constants';

import { Footer } from 'stile-shared/src/components/2024/common/Footer';
import { Header } from 'stile-shared/src/components/2024/common/Header';

import ESBadge from '../../../../stile-shared/assets/images/edsurge_pi_badge.png';
import SPPBadge from '../../../../stile-shared/assets/images/privacy/student_privacy_pledge.png';

export type PageLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export function PageLayout({ className, children }: PageLayoutProps) {
  return (
    <div className={`twentyTwentyFour ${className}`}>
      <Header
        logoColor="--stile-green-1"
        headerLinks={HEADER_LINK_SECTIONS}
        enquireNowGtagEvent="enquire_now_us"
      />
      <div className="main-content">{children}</div>
      <Footer
        locale={SITE_LOCALE}
        phone={CONTACT.us.phone}
        linkSections={FOOTER_LINK_SECTIONS}
        otherLinks={OTHER_SITE_LINKS}
        badges={[
          {
            text: 'Student Data Privacy Pledge',
            url: 'https://studentprivacypledge.org/',
            src: SPPBadge,
            width: 110,
          },
          {
            text: 'Find us on the EdSurge Product Index',
            url: 'https://index.edsurge.com/product/ultid/PF4C-3F2C-7F4F-4D53-8E/',
            src: ESBadge,
            width: 80,
          },
        ]}
        statement={CONTACT.us.address.descriptive}
      />
    </div>
  );
}
