import * as React from 'react';

import { FACEBOOK_URL, INSTAGRAM_URL, LOCALE_URLS, TWITTER_URL } from '../../../../constants';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../../2020/common/Link';
import { Logo } from '../../../2020/common/Logo';
import { Divider, Separator, Text } from '../../../2020/common/Primitives';
import { SiteSelector } from '../../../2020/common/SiteSelector';
import { Clamp } from '../../../2020/layout/Clamp';
import { Column } from '../../../2020/layout/Column';
import { FullWidthBackground } from '../../../2020/layout/FullWidthBackground';
import { Icon } from '../../../2020/layout/Icon';
import { Media } from '../../../2020/layout/Media';
import { Row } from '../../../2020/layout/Row';
import { Switcher } from '../../../2020/layout/Switcher';
import { Section } from '../../layout/Section';

const defaults = {
  social: [
    {
      text: 'Facebook',
      iconId: 'facebook',
      url: FACEBOOK_URL,
    },
    {
      text: 'Instagram',
      iconId: 'instagram',
      url: INSTAGRAM_URL,
    },
    {
      text: 'Twitter',
      iconId: 'twitter',
      url: TWITTER_URL,
    },
  ],
  badges: [],
};

type LinkContent = { text: string; url: string };

type FooterLinkSection = {
  title: string;
  links: LinkContent[];
};

type FooterProps = {
  locale: Exclude<keyof typeof LOCALE_URLS, 'x-default' | 'en'>;
  phone: {
    text: string;
    url: string;
  };
  linkSections: FooterLinkSection[];
  otherLinks: LinkContent[];
  statement: string;
  social?: {
    text: string;
    iconId: string;
    url: string;
  }[];
  badges?: {
    text: string;
    src: string;
    url: string;
    width: React.CSSProperties['width'];
  }[];
};

export function Footer({
  locale,
  phone,
  linkSections,
  otherLinks,
  statement,
  social = defaults.social,
  badges = defaults.badges,
}: FooterProps) {
  return (
    <Section backgroundColor="--stile-grey-8" textColor="--stile-grey-1" className="footer">
      <div
        style={{
          gridColumn: 'span 12',
        }}
      >
        <Column space={'32px'}>
          <Switcher threshold="600px" space={theme.space.m}>
            {linkSections.map((section) => (
              <Column as="nav" key={section.title} space={theme.space.xs} recursive role="list">
                <Text size="sm" semiBold>
                  {section.title}
                </Text>

                {section.links.map((link) => (
                  <Text
                    key={link.text}
                    size="s"
                    as={Link}
                    variant="hover"
                    url={link.url}
                    role="listitem"
                    data-testid="footer-page-link"
                    style={{ marginTop: 16 }}
                  >
                    {link.text}
                  </Text>
                ))}
              </Column>
            ))}
          </Switcher>

          <Text size="s" color="grey3" style={{ paddingTop: 30, paddingBottom: 20 }}>
            {statement}
          </Text>
        </Column>

        <hr style={{ marginTop: 32, marginBottom: 32 }} />

        <Media lessThan="s">
          <Column space={theme.space.m}>
            <Column space={theme.space.xs}>
              <Text size="s">Want to speak to a human? We’d be delighted to chat.</Text>

              <Row justify="space-between" space={'20px'}>
                <Text size="s" semiBold as={Link} variant="hover" url={phone.url}>
                  <Icon iconId="phone" iconSize="large" text={phone.text} inline={false} />
                </Text>

                <Row justify="flex-start" space={'20px'}>
                  {social.map((item) => (
                    <Text
                      key={item.text}
                      size="l"
                      as={Link}
                      variant="hover"
                      url={item.url}
                      title={item.text}
                    >
                      <Icon iconId={item.iconId} ariaText={item.text} />
                    </Text>
                  ))}
                </Row>
              </Row>
            </Column>

            <hr style={{ marginTop: 32, marginBottom: 32 }} />

            <Row justify="space-between">
              <Column space={theme.space.xxs}>
                <Link variant="hover" url="/" aria-label="Return home">
                  <Logo width="44px" />
                </Link>

                <Text size="xs">© Stile Education {new Date().getFullYear()}</Text>
              </Column>

              <SiteSelector locale={locale} />
            </Row>

            <Row as="nav" justify="flex-start" space={theme.space.xxs} role="list">
              {otherLinks.map((link, linkIndex) => (
                <Row key={link.text} space={theme.space.xxs} role="listitem">
                  <Text size="xs" as={Link} variant="hover" url={link.url}>
                    {link.text}
                  </Text>

                  {linkIndex < otherLinks.length - 1 && (
                    <Text size="xs" aria-hidden>
                      <Separator />
                    </Text>
                  )}
                </Row>
              ))}
            </Row>
          </Column>
        </Media>

        <Media greaterThanOrEqual="s">
          <Column space={theme.space.m}>
            <Row justify="space-between">
              <Row justify="flex-start" space={'21px'}>
                <Text size="s" semiBold as={Link} variant="hover" url={phone.url}>
                  <Icon iconId="phone" iconSize="large" text={phone.text} inline={false} />
                </Text>

                <Text size="s">Want to speak to a human? We’d be delighted to chat.</Text>
              </Row>

              <Row justify="flex-start" space={'21px'}>
                {social.map((item) => (
                  <Text
                    key={item.text}
                    size="l"
                    as={Link}
                    variant="hover"
                    url={item.url}
                    title={item.text}
                  >
                    <Icon iconId={item.iconId} ariaText={item.text} iconSize="fontSize" />
                  </Text>
                ))}
              </Row>
            </Row>

            <hr style={{ marginTop: 32, marginBottom: 32 }} />

            <Row justify="space-between" space={theme.space.s}>
              <Row justify="flex-start" space={theme.space.s}>
                <Link variant="hover" url="/" aria-label="Return home">
                  <Logo width="44px" />
                </Link>

                <Row justify="flex-start" space={'18px'}>
                  <Text size="s" style={{ marginLeft: 18 }}>
                    © Stile Education {new Date().getFullYear()}
                  </Text>

                  <Row as="nav" justify="flex-start" space={'18px'} role="list">
                    {otherLinks.map((link) => (
                      <Row key={link.text} space={theme.space.xxs} role="listitem">
                        <Text size="s" aria-hidden>
                          <Separator />
                        </Text>

                        <Text
                          size="s"
                          as={Link}
                          variant="hover"
                          url={link.url}
                          style={{ marginLeft: 18 }}
                        >
                          {link.text}
                        </Text>
                      </Row>
                    ))}
                  </Row>
                </Row>
              </Row>

              <Row justify="flex-start" space={theme.space.s}>
                {badges.map((item) => (
                  <Link key={item.text} url={item.url} openInNewTab>
                    <img width={item.width} src={item.src} alt={item.text} />
                  </Link>
                ))}
              </Row>

              <SiteSelector
                locale={locale}
                style={{ padding: '0 12px' }}
                selectIconStyles={{ padding: '0 16px' }}
              />
            </Row>
          </Column>
        </Media>
      </div>
    </Section>
  );
}
