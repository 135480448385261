import * as React from 'react';

import './Grid.css';

type GridProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Displays content in a simple grid.
 */
export const Grid = ({ children, className, ...props }: React.PropsWithChildren<GridProps>) => {
  return (
    <div className={`grid-layout ${className}`} {...props}>
      {children}
    </div>
  );
};
